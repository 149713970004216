
import { history } from "./history" 
import axios from "axios"
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' || 
    window.location.hostname === '[::1]' || 
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) { 
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) { 
      return
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config)

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://bit.ly/CRA-PWA'
          )
        })
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config)
      }
    })
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing
        if (installingWorker == null) {
          return
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
              )

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration)
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.')

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration)
              }
            }
          }
        }
      }
    })
    .catch(error => {
      console.error('Error during service worker registration:', error)
    })
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type')
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config)
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    })
}

export const  generateRefreshToken = async () => 
{
var resp;
  try {
    var refreshToken =  localStorage.getItem('refreshToken');
    var ipurl= global.config.geturl.ipaddress.url
    await axios({
      method: 'Post',
      url: ipurl + 'employer/refreshToken/',
      crossdomain: true,

      params: {
        token: refreshToken,       
      }
    })
      .then(async response => {
        if(response.data && response.data['tokenReult'] && response.data['tokenReult']['status'] == 498)
        {
          resp = 0;
          localStorage.clear();
          setTimeout(() => {
            history.push("/");
          }, 1000);
        }
        else{       
       
        if (response.data.hasOwnProperty('tokenReult')) {
          if (response.data.tokenReult.hasOwnProperty('accessToken')) {
            localStorage.setItem('accessToken', response.data.tokenReult.accessToken)
            localStorage.setItem('refreshToken', response.data.tokenReult.refreshToken)
            resp =  1
          }
          
        }
      }
      })
      .catch(error => {
      //  error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "joinus", 0, error.toString());
      });
  } catch (error) {
    //error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "joinus", 0, error.toString());
  }
   return resp
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister()
    })
  }
}
