module.exports = global.config = {
  geturl: {
    ipaddress: {
      // url: "https://devapi.jobanya.com/api/controller/", // DEV
      url: "https://apitest.jobanya.com/api/controller/"// Test
      //  url: "https://apilive.jobanya.com/api/controller/"// LIVE
      // url: "https://apiuat.thebestjobs.in/api/controller/"   //UAT 
    },
    // rest of your translation object
  },
  // other global config variables you wish
};
